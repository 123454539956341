import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { EModal } from '@contract/modal';
import { Screen } from '@component/Screen';
import { Inject } from '@plugin/inversify';
import { AuthRepositoryType } from '../../../contracts/repositories';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let ForgotPasswordModal = class ForgotPasswordModal extends AbstractModal {
    constructor() {
        super(...arguments);
        /**
         * Object that collects User input.
         */
        this.formData = { email: '' };
        /**
         * Determines whether the component is currently performing any async actions.
         */
        this.isActing = false;
    }
    /**
     * Handles the `@submit` event on the `<form>` element.
     */
    onSubmit() {
        this.requestPasswordReset();
    }
    /**
     * Requests the password reset.
     */
    async requestPasswordReset() {
        this.isActing = true;
        const { email } = this.formData;
        this.authRepository.forgotPassword(email)
            .then(() => {
            this.swapModal(EModal.MessageSent);
        })
            .catch(error => {
            this.$logger(error, 'debug');
            this.swapModal(EModal.AccountNotFound);
        })
            .finally(() => {
            this.isActing = false;
        });
    }
};
__decorate([
    Inject(AuthRepositoryType)
], ForgotPasswordModal.prototype, "authRepository", void 0);
ForgotPasswordModal = __decorate([
    Component({ name: 'ForgotPasswordModal', components: { Screen } })
], ForgotPasswordModal);
export { ForgotPasswordModal };
export default ForgotPasswordModal;
